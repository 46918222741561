import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card, CardHeader, CardMedia, makeStyles, Theme, createStyles, CardActions, IconButton, Button } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons";
import { Link } from "gatsby";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      maxWidth: 500,
      margin: '0 auto',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8)
    },
    header: {
      textAlign: 'center'
    },
    media: {
      height: 0,
      paddingTop: '56.25%'
    },
    actions: {
      justifyContent: 'center',
      '& a': {
        textDecoration: 'none'
      }
    }
  })
);

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <Layout page='Error 404'>
      <SEO title="404: No encontrado" />

      <div className={classes.error}>
        <Card elevation={4}>
          <CardHeader className={classes.header} title='ERROR 404' subheader='Lo sentimos, la página que intentas buscar no existe.' />
          <CardMedia className={classes.media} image={require('../assets/images/404.jpg')} />
          <CardActions className={classes.actions}>
            <Link to='/'>
              <Button variant='contained' color='primary' startIcon={<ArrowBack />}>Volver al inicio</Button>
            </Link>
          </CardActions>
        </Card>
      </div>
    </Layout>
  )
}

export default NotFoundPage
